<template>
  <div
    class="flex flex-grow-0 items-center text-center font-semibold rounded-full w-auto justify-center px-2 py-0 text-xs pill mx-0-25"
  >
    {{ count }}
  </div>
</template>

<script setup lang="ts">
import { DEFAULT_RELATION_COLOR } from "@/helpers/constants/entityProfiles";
import { computed } from "vue";

const props = defineProps({
  count: {
    type: Number
  },
  color: {
    type: String,
    default: DEFAULT_RELATION_COLOR
  }
});

const textColor = computed(() => {
  if (props.color.includes("#")) {
    return props.color;
  }
  return `rgb(${props.color})`;
});

const bgColor = computed(() => {
  if (props.color.includes("#")) {
    return `${props.color}1A`;
  }
  return `rgba(${props.color}, 0.1)`;
});
</script>

<style scoped>
.pill {
  color: v-bind(textColor);
  background-color: v-bind(bgColor);
}
</style>
