import type { Relation } from "@/enums/entityProfiles";

export const RELATION_COLORS: Partial<Record<Relation, string>> = {
  subsidiary: "#7800D3",
  owner: "#3769D6",
  customer: "#3CD2CD",
  vendor: "#EC0B7B"
} as const;

export const DEFAULT_RELATION_COLOR = "#C2C6CD";

export const OWNER_COLORS: string[] = [
  "#7E7CFF",
  "#2C92FF",
  "#00E240",
  "#00BD4F",
  "#FFCC00",
  "#FF6D04",
  "#E9211A"
];
